/* @import url("https://fonts.cdnfonts.com/css/gibson?styles=69957,69958"); */

/* if needed in fonts folder are few variants of Gibson font, so yuo can apply iy with font-face */
@font-face {
    font-family: "Gibson";
    src: local("Gibson Regular"), local("Gibson-Regular"), url("/src/fonts/Gibson-Regular.otf");
    font-style: normal;
}
@font-face {
    font-family: "Gibson-Medium";
    src: local("Gibson Medium"), local("Gibson-Medium"), url("/src/fonts/Gibson-Medium.otf");
    font-style: normal;
}
@font-face {
    font-family: "Gibson-Semibold";
    src: local("Gibson Semibold"), local("Gibson-Semibold"), url("/src/fonts/Gibson-Semibold.otf");
    font-style: normal;
}
@font-face {
    font-family: "Gibson-Heavy";
    src: local("Gibson Heavy"), local("Gibson-Heavy"), url("/src/fonts/Gibson-Heavy.otf");
    font-style: normal;
}

#chartjs-tooltip {
    z-index: 2;
    padding: 16px 8px 10px 8px;
    position: absolute;
    background-color: white;
    font-size: 14px;
    box-shadow: 0px 4px 10px rgba(110, 102, 102, 0.2);
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    pointer-events: none;
}

.tooltip-title {
    height: 24px;
    line-height: 120%;
}

.tooltip-subtext {
    line-height: 118%;
    color: #333333;
}

.temp-profile-chart-tooltip-content {
    display: flex;
}

.tpc-temp-legend-caption {
    display: flex;
    flex-direction: column;
}

.tpc-temp-legend-first {
    margin-right: 20px;
}

.temp-value {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
}

.datetime-value {
    font-weight: normal;
    font-size: 10px;
    line-height: 16px;
}

.tpc-max-temp-legend-circle {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    margin-right: 12px;
    background-color: #270adc;
}

.tpc-min-temp-legend-circle {
    width: 16px;
    height: 16px;
    margin-right: 12px;
    border-radius: 50%;
    background-color: #999999;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type="number"] {
    -moz-appearance: textfield; /* Firefox */
}

.MuiBreadcrumbs-li:last-child > p > a {
    color: rgba(0, 0, 0, 0.87);
}

.Toastify__toast-container {
    width: initial;
    min-width: var(--toastify-toast-width);
}

.Toastify__toast {
    background: initial;
    padding: 0;
    cursor: initial;
    min-height: 0;
    box-shadow: initial;
    overflow: initial;
}

.Toastify__toast-body {
    padding: 0;
}

body {
    color: "#181818" !important;
    font-family: "Gibson" !important;
    font-size: "1rem" !important;
    background-color: #fbfafe !important;
}

/* time OK button */
.ant-btn.ant-btn-sm {
    padding: 0.25rem 1rem;
    height: 2rem;
    color: white;
    background-color: #270adc;
}

/* time OK btn disabled */
.ant-btn-primary:disabled,
.ant-btn-primary.ant-btn-disabled {
    background-color: #e8e8e8;
    color: #999999;
}
/* time OK btn hover */
.ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover {
    background-color: #533cdc;
}
